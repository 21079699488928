<template>
  <div class="order_generator">
    <b-tabs card pills>
      <b-tab title="Dates &amp; Addresses" lazy>
        <h6>Select Dates</h6>
        <v-date-picker
          title-position="left"
          :is-expanded="false"
          mode="multiple"
          v-model="datepicker_dates"
          :disabled-dates="[{ weekdays: [1] }]"
          show-caps
          is-inline
        >
        </v-date-picker>
        <hr />
        <br />
        <b-card header="Select Addresses">
          <!-- <b-button @click="addPoint">Add Point</b-button>
        <q-spinner v-if="loading_areas" class="mx-1" color="primary" size="35px" :thickness="2" />
        <PendingTable
          :properties="[{ property_key: 'center_point', type: 'point' }]"
          :_fields="[{ key: 'center_point' }, { key: 'area_names' }]"
          :relations.sync="ideal_params.points"
          :enableRemove="true"
          :small="true"
        ></PendingTable> -->

          <RelationshipView :relationship="order_deliver_to_relationship" :params.sync="order"></RelationshipView>
        </b-card>
      </b-tab>
      <b-tab title="Items" active lazy>
        <b-card header="Select Products">
          <RelationshipView
            :relationship="$d.getRelationship('Order', 'IncludeProducts')"
            :params.sync="order"
            :except_virtual_property_keys="['final_address_pricing']"
            :_exclude_select_fields="[
              { property: { property_key: 'final_address_pricing' } },
              { property: { property_key: 'address_pricing' } },
              { property: { property_key: 'account_pricing' } },
              { property: { property_key: 'billing_account_pricing' } },
              { property: { property_key: 'warehouses' } },
            ]"
            :_exclude_pending_fields="[
              { property: { property_key: 'final_address_pricing' } },
              { property: { property_key: 'address_pricing' } },
              { property: { property_key: 'account_pricing' } },
              { property: { property_key: 'billing_account_pricing' } },
              { property: { property_key: 'price' } },
              { property: { property_key: 'warehouses' } },
            ]"
            :_index_params="{
              with_childs: true, //TODO: even though relatively low cost, disable
            }"
          ></RelationshipView>
        </b-card>
        <br />
        <b-card header="Select Packages">
          <RecursiveView
            :relationship="$d.getRelationship('Order', 'IncludeOrderPackages')"
            :params.sync="order"
            :_exclude_fields="[{ property: { property_key: 'order_id' } }]"
            @itemAdded="
              (order_packages, index) => {
                order_packages[index]['_id'] = String(new Date().getTime()) + '_' + $d.randStr();
              }
            "
          >
            <template v-slot:Order_IncludeOrderPackages="{ relationship, params, disabled }">
              <ClassPackageDetails
                :params.sync="params"
                :disabled="disabled"
                :config="{ main_class_name: 'OrderPackage', main_rel_name: 'IncludeOrderPackageGroups', group_class_name: 'OrderPackageGroup' }"
              />
            </template>
          </RecursiveView>
        </b-card>
        <br />
        <b-card header="Claim Vouchers">
          <RelationshipView
            :relationship="$d.getRelationship('Order', 'ClaimVouchers')"
            :params.sync="order"
            :_index_params="{
              with_childs: true, //TODO: even though relatively low cost, disable
            }"
          ></RelationshipView>
        </b-card>
        <b-card no-body v-if="false">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle="'involved_tangibles'" variant="white">Show Involved Tangibles ({{ involved_tangibles.length }})</b-btn>
          </b-card-header>
          <b-collapse id="involved_tangibles" accordion="my-accordion_involved_tangibles">
            <b-card-body>
              <b-table
                class="quota_table"
                v-if="true"
                small
                striped
                hover
                responsive
                :items="involved_tangibles"
                :fields="[
                  {
                    key: 'name',
                    label: 'name',
                  },
                  {
                    key: 'quantity',
                    label: 'Qty',
                  },
                ]"
              >
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-tab>
      <b-tab title="Selection" lazy @click="quotaCheck">
        <div v-if="!got_address" class="alert alert-warning">No Delivery Addresses Available</div>
        <div v-else>
        <h6>Select Combos</h6>
            <c-switch
              v-b-tooltip.hover title="Use Materialized"
              class="mx-2"
              v-model="ideal_params['use_materialized']"
              color="success"
              :size="small ? 'sm' : null"
              label
              outline
              :dataOn="'\u2713'"
              :dataOff="'\u2715'"
              :value="true"
            />
        <b-button @click="quotaCheck" :disabled="loading_quota_check">Check Quota</b-button>
        <q-spinner v-if="loading_quota_check" class="mx-1" color="primary" size="35px" :thickness="2" />
        <span v-if="!loading_quota_check && timetaken">&nbsp; ({{ timetaken / 1000 }}s)</span>
        <div v-if="quota_check_error_message" v-html="quota_check_error_message" class="alert alert-danger" role="alert"></div>
        <br />

        <b-card no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle="'accordion_created_orders'" variant="white">Show Created Orders</b-btn>
          </b-card-header>
          <b-collapse id="accordion_created_orders" accordion="my-accordion_created" visible>
            <b-card-body>
              <Search
                ref="orderSearchRef"
                :default_per_page="3"
                :fields="$d.getClassBTableFields('Order')"
                :class_name="'Order'"
                :_index_params="$d.relationIndexParams('Order', 'UnderAccount', account.id)"
                small
                :allow_bottom_pagination="false"
                :allow_per_page="false"
                :allow_searchbar="false"
                :allow_predefined_filter="false"
                :allow_json_filter="false"
                :allow_properties_filter="false"
                :allow_relationships_filter="false"
                :allow_select_fields="false"
                :allow_map="false"
                :enable_map_default="false"
              />
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle="'accordion_selected_orders'" variant="white">Show Selected Orders ({{ computed_selected_orders.length }})</b-btn>
          </b-card-header>
          <b-collapse id="accordion_selected_orders" accordion="my-accordion_selected" visible>
            <b-card-body>
              <b-table
                class="quota_table"
                small
                striped
                hover
                responsive
                :items="computed_selected_orders"
                sort-by="id"
                :fields="[
                  {
                    key: 'order_actions',
                    label: '',
                  },
                  {
                    key: 'from_date',
                    label: 'Date',
                    sortable: true,
                  },
                  {
                    key: 'day_of_week_name',
                    label: 'Day',
                    tdClass: dayOfWeekClass,
                    sortable: true,
                  },
                  {
                    key: 'service_route_name',
                    label: 'ServiceRoute',
                    sortable: true,
                  },
                  {
                    key: 'fleet_names',
                    label: 'Fleet',
                    sortable: true,
                  },
                  {
                    key: 'address_str',
                    label: 'Address',
                    sortable: true,
                  },
                  {
                    key: 'actions',
                    label: '',
                  },
                  {
                    key: 'info',
                    label: 'Info',
                  },
                ]"
              >
                <!-- @row-clicked="
                  (item, index) => {
                    toggleDetails(computed_selected_orders, index);
                  }
                " -->
                <!-- 
                  {
                    key: 'warehouse_name',
                    label: 'Warehouse',
                  },
                  {
                    key: 'area_name',
                    label: 'Area',
                  },
                  {
                    key: 'tangible_names',
                    label: 'Tangibles',
                  },
                -->
                <template v-slot:row-details="row">
                  {{ row.item }}
                </template>
                <template v-slot:cell(actions)="row">
                  <!-- <b-button size="sm" @click="addCombo(row.item)"> Add </b-button> -->
                  <!-- {{ row.item.orderable_tangibles }} -->
                  <div v-for="(orderable_tangible, index) in row.item.orderable_tangibles" :key="index">
                    <!-- {{ orderable_tangible }} -->
                    <b-button v-if="orderable_tangible['product']" size="sm" @click="removeOrderableTangible(row.item, orderable_tangible, index)"
                      ><i class="fa fa-minus"></i>&nbsp;<i class="fas fa-box"></i>&nbsp;{{
                        orderable_tangible['product']['include_products']['quantity']
                      }}&nbsp;-&nbsp;{{ orderable_tangible['product']['name'] }}</b-button
                    >
                    <b-button v-if="orderable_tangible['order_package']" size="sm" @click="removeOrderableTangible(row.item, orderable_tangible, index)"
                      ><i class="fa fa-minus"></i>&nbsp;<i class="fas fa-boxes"></i>&nbsp;{{
                        orderable_tangible['order_package']['order_package_with_package'][0]['name']
                      }}</b-button
                    >
                    <b-button v-if="orderable_tangible['voucher']" size="sm" @click="removeOrderableTangible(row.item, orderable_tangible, index)"
                      ><i class="fa fa-minus"></i>&nbsp;<i class="fas fa-ticket-alt"></i>&nbsp;{{
                        orderable_tangible['voucher']['claim_vouchers']['claim_quantity']
                      }}&nbsp;-&nbsp;{{ orderable_tangible['voucher']['name'] }}</b-button
                    >
                  </div>
                  <!-- <b-button size="sm" @click="row.toggleDetails"> {{ row.detailsShowing ? 'Hide' : 'Show' }} Details </b-button> -->
                </template>
                <template v-slot:cell(order_actions)="row">
                  <b-button size="sm" @click="createOrder(row.item)" :disabled="row.item['has_issue'] || create_loading"
                    ><i class="fas fa-plus"></i>&nbsp;{{ create_loading ? 'Loading...' : 'Create' }}</b-button
                  >
                </template>
                <template v-slot:cell(info)="row">
                  <OrderGeneratorInfo name="selected" :info="row.item" :index="row.index" />
                </template>
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle="'accordion_positive_orders'" variant="white">Show Achievable Orders ({{ achievable_orders.length }})</b-btn>
          </b-card-header>
          <b-collapse id="accordion_positive_orders" accordion="my-accordion_achievable" visible>
            <b-card-body>
              <b-table
                class="quota_table"
                small
                striped
                hover
                responsive
                :items="achievable_orders"
                sort-by="id"
                :fields="[
                  {
                    key: 'from_date',
                    label: 'Date',
                    sortable: true,
                  },
                  {
                    key: 'day_of_week_name',
                    label: 'Day',
                    tdClass: dayOfWeekClass,
                    sortable: true,
                  },
                  {
                    key: 'service_route_name',
                    label: 'ServiceRoute',
                    sortable: true,
                  },
                  {
                    key: 'fleet_names',
                    label: 'Fleet',
                    sortable: true,
                  },
                  {
                    key: 'address_str',
                    label: 'Address',
                    sortable: true,
                  },
                  {
                    key: 'actions',
                    label: '',
                  },
                  {
                    key: 'info',
                    label: 'Info',
                  },
                ]"
              >
                <!-- @row-clicked="
                  (item, index) => {
                    toggleDetails(achievable_orders, index);
                  }
                " -->
                <!-- 
                  {
                    key: 'warehouse_name',
                    label: 'Warehouse',
                  },
                  {
                    key: 'area_name',
                    label: 'Area',
                  },
                  {
                    key: 'tangible_names',
                    label: 'Tangibles',
                  },
                -->
                <template v-slot:row-details="row">
                  {{ row.item }}
                </template>
                <template v-slot:cell(actions)="row">
                  <!-- <b-button size="sm" @click="addCombo(row.item)"> Add </b-button> -->
                  <!-- {{ row.item.orderable_tangibles }} -->
                  <div v-for="(orderable_tangible, index) in row.item.orderable_tangibles" :key="index">
                    <!-- {{ orderable_tangible }} -->
                    <b-button
                      v-if="orderable_tangible['product']"
                      size="sm"
                      @click="addOrderableTangible(row.item, orderable_tangible)"
                      :variant="orderable_tangible['product']['include_products']['quantity'] <= 0 ? 'danger' : 'secondary'"
                      ><i class="fa fa-plus"></i>&nbsp;<i class="fas fa-box"></i>&nbsp;{{
                        orderable_tangible['product']['include_products']['quantity']
                      }}&nbsp;-&nbsp;{{ orderable_tangible['product']['name'] }}</b-button
                    >
                    <b-button
                      v-if="orderable_tangible['order_package']"
                      size="sm"
                      @click="addOrderableTangible(row.item, orderable_tangible)"
                      :variant="orderable_tangible['order_package']['remove'] ? 'danger' : 'secondary'"
                      ><i class="fa fa-plus"></i>&nbsp;<i class="fas fa-boxes"></i>&nbsp;{{
                        orderable_tangible['order_package']['order_package_with_package'][0]['name']
                      }}</b-button
                    >
                    <b-button
                      v-if="orderable_tangible['voucher']"
                      size="sm"
                      @click="addOrderableTangible(row.item, orderable_tangible)"
                      :variant="orderable_tangible['voucher']['claim_vouchers']['claim_quantity'] <= 0 ? 'danger' : 'secondary'"
                      ><i class="fa fa-plus"></i>&nbsp;<i class="fas fa-ticket-alt"></i>&nbsp;{{
                        orderable_tangible['voucher']['claim_vouchers']['claim_quantity']
                      }}&nbsp;-&nbsp;{{ orderable_tangible['voucher']['name'] }}</b-button
                    >
                  </div>
                  <!-- <b-button size="sm" @click="row.toggleDetails"> {{ row.detailsShowing ? 'Hide' : 'Show' }} Details </b-button> -->
                </template>
                <template v-slot:cell(info)="row">
                  <OrderGeneratorInfo name="achievable" :info="row.item" :index="row.index" />
                </template>
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>

        <c-switch color="success" label variant="pill" size="sm" v-model="show_advanced" /> Show Advanced Reasoning
        <b-card no-body v-if="show_advanced">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle="'accordion_negative'" variant="white">Show Unachievable Combos ({{ order_quota_negative.length }})</b-btn>
          </b-card-header>
          <b-collapse id="accordion_negative" accordion="my-accordion_unachievable">
            <b-card-body>
              <b-table
                class="quota_table"
                v-if="true"
                small
                striped
                hover
                responsive
                :items="order_quota_negative"
                :fields="[
                  {
                    key: 'from_date',
                    label: 'Date',
                  },
                  {
                    key: 'day_of_week_name',
                    label: 'Day',
                    tdClass: this.dayOfWeekClass,
                  },
                  {
                    key: 'tangible_name',
                    label: 'Tangible',
                  },
                  {
                    key: 'area_name',
                    label: 'Area',
                  },
                  {
                    key: 'limited_quantity_per_day',
                    label: 'Max',
                  },
                  {
                    key: 'consumed_quantity_per_day',
                    label: 'Consumed',
                  },
                  {
                    key: 'reason',
                    label: 'Reason',
                  },
                  {
                    key: 'info',
                    label: 'Info',
                  },
                ]"
                @row-clicked="
                  (item, index) => {
                    toggleDetails(order_quota_negative, index);
                  }
                "
              >
                <template v-slot:cell(info)="row">
                  <OrderGeneratorInfo name="unachievable" :info="row.item" :index="row.index" />
                </template>
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body v-if="show_advanced">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle="'accordion_invalid'" variant="white">Show Invalid Combos ({{ order_quota_invalid.length }})</b-btn>
          </b-card-header>
          <b-collapse id="accordion_invalid" accordion="my-accordion">
            <b-card-body>
              <b-table
                class="quota_table"
                v-if="true"
                small
                striped
                hover
                responsive
                :items="order_quota_invalid"
                :fields="[
                  {
                    key: 'from_date',
                    label: 'Date',
                  },
                  {
                    key: 'day_of_week_name',
                    label: 'Day',
                    tdClass: this.dayOfWeekClass,
                  },
                  {
                    key: 'tangible_name',
                    label: 'Tangible',
                  },
                  {
                    key: 'area_name',
                    label: 'Area',
                  },
                  {
                    key: 'reason',
                    label: 'Reason',
                  },
                  {
                    key: 'info',
                    label: 'Info',
                  },
                ]"
                @row-clicked="
                  (item, index) => {
                    toggleDetails(order_quota_invalid, index);
                  }
                "
              >
                <template v-slot:cell(info)="row">
                  <OrderGeneratorInfo name="invalid" :info="row.item" :index="row.index" />
                </template>
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>
        </div>
      </b-tab>
    </b-tabs>

    <OrderModal
      ref="orderModalRef"
      @close="
        () => {
          quotaCheck();
          $refs['orderSearchRef'].get();
        }
      "
      :fixed_params="order_modal_fixed_params"
      :_params="order_modal_params"
    >
    </OrderModal>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import { Switch as cSwitch } from '@coreui/vue';

import { setupCalendar, Calendar, DatePicker, Popover } from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';

setupCalendar({
  firstDayOfWeek: 1, // Monday,
});

import { QSpinner } from 'quasar';

export default {
  components: {
    cSwitch,
    EditableCard: () => import('@/descriptor/coreui/EditableCard'),
    Search: () => import('@/descriptor/coreui/Search'),
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
    RecursiveView: () => import('@/descriptor/coreui/components/modal/RecursiveView'),
    RelationshipView: () => import('@/descriptor/coreui/components/modal/RelationshipView'),
    FormInput: () => import('@/descriptor/coreui/components/modal/FormInput'),
    PropertiesView: () => import('@/descriptor/coreui/components/modal/PropertiesView'),
    PropertiesFilter: () => import('@/descriptor/coreui/components/modal/PropertiesFilter'),
    Dropdown: () => import('@/descriptor/coreui/components/modal/Dropdown'),
    PendingTable: () => import('@/descriptor/coreui/components/modal/PendingTable'),
    ClassPackageDetails: () => import('@/views/descriptor/classes/Class/Package/ClassPackageDetails'),
    OrderModal: () => import('@/views/descriptor/classes/Order/OrderModal'),
    OrderGeneratorInfo: () => import('./OrderGeneratorInfo'),
    'v-date-picker': DatePicker,
    QSpinner,
  },
  props: {
    account: {
      required: true,
    },
    billing_account: {
      required: true,
    },
  },
  data: () => {
    return {
      loading_areas: null,

      loading_quota_check: null,
      quota_check_error_message: null,
      got_address: null,

      ideal_params: {
        // from_date: null,
        // to_date: null,
        dates: [],
        tangibles: [],
        // areas: [{ id: 1, name: '1' }, { id: 3, name: '3' }, { id: 5, name: '5' }],
        points: [],
        use_materialized: true,
      },
      order: {
        order_deliver_to: [],
        order_bill_to: [],
        // order_bill_to: [{ id: 1 }],
        // order_deliver_to: [{ id: 1 }],
        order_include_products: [],
        order_include_order_packages: [],
        order_claim_vouchers: [],
      },
      quota_data: [],
      timetaken: null,

      order_quota_invalid: [],
      order_quota_negative: [],
      order_quota_positive: [],

      selected_orders: [],

      show_advanced: false,

      order_modal_fixed_params: {},
      order_modal_params: {},

      create_loading: false,
    };
  },
  computed: {
    datepicker_dates: {
      // getter
      get: function() {
        let datepicker_dates = [];
        this.ideal_params['dates'].forEach(date => {
          // console.log('from x', date)
          let date2 = moment(date).toDate();
          // console.log('to x', date2)
          datepicker_dates.push(date2);
        });
        return datepicker_dates;
      },
      // setter
      set: function(dates) {
        this.ideal_params['dates'] = [];
        if (dates) {
          dates.forEach(date => {
            // console.log('from y', date);
            let date2 = this.$d.momentFormatDate(moment(date)); //strip of timezone
            // console.log('to y', date2);
            this.ideal_params['dates'].push(date2);
          });
        }
      },
    },
    order_deliver_to_relationship() {
      let order_deliver_to_relationship = this.$d.getRelationship('Order', 'DeliverTo');
      _.set(order_deliver_to_relationship, ['to', 'rules', 'default', 'max'], 'infinite');
      return order_deliver_to_relationship;
    },
    computed_quota_data() {
      let computed_quota_data = _.map(this.quota_data, ori_quota_datum => {
        let quota_datum = _.clone(ori_quota_datum);

        //assign an ID for each combo
        let keys = [];
        keys.push(quota_datum.from_date);
        keys.push(quota_datum.warehouse_id);
        keys.push(quota_datum.service_route_id);
        keys.push(quota_datum.address_id); //TODO; need to also grou by address
        quota_datum['id'] = keys.join('_');

        //defaults

        if (!quota_datum['consumed_quantity_per_day']) {
          quota_datum['consumed_quantity_per_day'] = 0;
        }
        if (!quota_datum['consumed_volume_per_day']) {
          quota_datum['consumed_volume_per_day'] = 0;
        }
        if (!quota_datum['consumed_weight_per_day']) {
          quota_datum['consumed_weight_per_day'] = 0;
        }
        if (!quota_datum['consumed_addresses_per_day']) {
          quota_datum['consumed_addresses_per_day'] = 0;
        }

        //cast

        quota_datum['consumed_quantity_per_day'] = Number(quota_datum['consumed_quantity_per_day']);
        quota_datum['consumed_volume_per_day'] = Number(quota_datum['consumed_volume_per_day']);
        quota_datum['consumed_weight_per_day'] = Number(quota_datum['consumed_weight_per_day']);
        quota_datum['consumed_addresses_per_day'] = Number(quota_datum['consumed_addresses_per_day']);

        quota_datum['limited_quantity_per_day'] = Number(quota_datum['limited_quantity_per_day']);
        quota_datum['limited_volume_per_day'] = Number(quota_datum['limited_volume_per_day']);
        quota_datum['limited_weight_per_day'] = Number(quota_datum['limited_weight_per_day']);
        quota_datum['limited_addresses_per_day'] = Number(quota_datum['limited_addresses_per_day']);

        //remaining
        this.calcRemaining(quota_datum);

        quota_datum['has_issue'] = this.hasIssue(quota_datum);

        return quota_datum;
      });
      return computed_quota_data;
    },
    involved_tangibles() {
      let involved_tangibles_ungrouped = [];
      this.orderable_tangibles.forEach(orderable_tangible => {
        orderable_tangible['tangible_quantities'].forEach(tangible_quantity => {
          return involved_tangibles_ungrouped.push(_.cloneDeep(tangible_quantity));
        });
      });

      let involved_tangibles = _(involved_tangibles_ungrouped)
      .groupBy('id')
      .map((involved_tangibles_grouped, id) => ({
        id: id,
        name: involved_tangibles_grouped[0].name,
        quantity: _.sumBy(involved_tangibles_grouped, (involved_tangible) => {
          return Number(involved_tangible.quantity);
        }),
      }))
      .value()

      console.log('involved_tangibles', involved_tangibles);
      return involved_tangibles;
    },
    orderable_tangibles() {
      let orderable_tangibles = [];

      this.order['order_include_products'].forEach(ori_product => {
        let product = _.cloneDeep(ori_product);
        let orderable_tangible = {
          product: product,
          tangible_quantities: [],
        };
        product['product_using_tangible'].forEach(tangible => {
          let tangible_quantity = _.cloneDeep(tangible);

          tangible_quantity['quantity'] = product['include_products']['quantity'];
          orderable_tangible['tangible_quantities'].push(tangible_quantity);
        });
        orderable_tangibles.push(orderable_tangible);
      });

      this.order['order_include_order_packages'].forEach(order_package => {
        let orderable_tangible = {
          order_package: order_package,
          tangible_quantities: [],
        };
        order_package['order_package_include_order_package_groups'].forEach(order_package_group => {
          order_package_group['order_package_group_include_products'].forEach(product => {
            product['product_using_tangible'].forEach(tangible => {
              let tangible_quantity = _.cloneDeep(tangible);
              tangible_quantity['quantity'] = product['include_products']['quantity'];
              orderable_tangible['tangible_quantities'].push(tangible_quantity);
            });
          });
        });
        orderable_tangibles.push(orderable_tangible);
      });

      this.order['order_claim_vouchers'].forEach(voucher => {
        let orderable_tangible = {
          voucher: voucher,
          tangible_quantities: [],
        };
        voucher['voucher_for_tangible'].forEach(tangible => {
          let tangible_quantity = _.cloneDeep(tangible);
          tangible_quantity['quantity'] = voucher['claim_vouchers']['claim_quantity'];
          orderable_tangible['tangible_quantities'].push(tangible_quantity);
        });
        orderable_tangibles.push(orderable_tangible);
      });

      console.log('orderable_tangibles', orderable_tangibles);
      return orderable_tangibles;
    },
    achievable_orders() {
      let achievable_orders = _.values(
        _.groupBy(this.order_quota_positive, combo => {
          return combo['id'];
        })
      );

      achievable_orders = _.map(achievable_orders, combos => {
        let achievable_order = Object.assign({}, combos[0]);

        //remove data specific to tangible
        delete achievable_order['tangible_id'];
        delete achievable_order['tangible_name'];
        delete achievable_order['limited_quantity_per_day'];
        delete achievable_order['consumed_quantity_per_day'];
        delete achievable_order['remaining_quantity_per_day'];

        let address = _.find(this.order['order_deliver_to'], { id: Number(achievable_order['address_id']) });

        let address_str = '';

        if (address['line1']) {
          address_str += address['line1'];
        }
        if (address['line2']) {
          address_str += ', ';
          address_str += address['line2'];
        }
        if (address['line3']) {
          address_str += ', ';
          address_str += address['line3'];
        }
        if (address['city']) {
          address_str += ', ';
          address_str += address['city'];
        }
        if (address['state']) {
          address_str += ', ';
          address_str += address['state'];
        }
        achievable_order['address_str'] = address_str;

        achievable_order['combos'] = combos;

        let fleet_names = '';
        achievable_order['fleet_names'] = [];
        for (let i = 0; i < achievable_order['combos'].length; i++) {
          console.log('fleet_name', achievable_order['combos'][i]['fleet_name']);
          fleet_names += achievable_order['combos'][i]['fleet_name'] + "; ";
        }

        var check_duplicate = fleet_names.split('; ');
    
        fleet_names = check_duplicate.filter(function(value, index, self) { 
            return self.indexOf(value) === index;
        }).join('; ');

        achievable_order['fleet_names'] = fleet_names;
        // achievable_order['tangible_names'] = _.map(combos, combo => combo['tangible_name']).join('\n');

        let selected_order = _.find(this.selected_orders, { id: achievable_order['id'] });

        achievable_order['orderable_tangibles'] = [];

        this.orderable_tangibles.forEach(ori_orderable_tangible => {
          //for each orderable item, check that the item's tangibles are sufficient. If not, make adjustments to the item quantity
          let orderable_tangible = _.cloneDeep(ori_orderable_tangible);

          let can_order = true;

          orderable_tangible['tangible_quantities'].forEach(tangible_quantity => {
            //get total quantity of tangible that is already selected
            let same_item_found_in_selected_order = false;
            if (selected_order) {
              selected_order['orderable_tangibles'].forEach(selected_orderable_tangible => {
                let is_same_item = false;
                if (orderable_tangible['product'] && selected_orderable_tangible['product']) {
                  is_same_item = orderable_tangible['product']['id'] == selected_orderable_tangible['product']['id'];
                } else if (orderable_tangible['order_package'] && selected_orderable_tangible['order_package']) {
                  is_same_item = orderable_tangible['order_package']['_id'] == selected_orderable_tangible['order_package']['_id'];
                } else if (orderable_tangible['voucher'] && selected_orderable_tangible['voucher']) {
                  is_same_item = orderable_tangible['voucher']['id'] == selected_orderable_tangible['voucher']['id'];
                }

                if (is_same_item) {
                  same_item_found_in_selected_order = true;
                }
              });
            }

            let combo_selected_consumed_tangible_quantity = 0;
            if (selected_order) {
              selected_order['orderable_tangibles'].forEach(selected_orderable_tangible => {
                selected_orderable_tangible['tangible_quantities'].forEach(selected_tangible_quantity => {
                  if (selected_tangible_quantity['id'] == tangible_quantity['id']) {
                    combo_selected_consumed_tangible_quantity += selected_tangible_quantity['quantity'];
                  }
                });
              });
            }

            let total_selected_consumed_tangible_quantity = 0;
            this.selected_orders.forEach(selected_order => {
              selected_order['orderable_tangibles'].forEach(selected_orderable_tangible => {
                let is_same_item = false;
                if (orderable_tangible['product'] && selected_orderable_tangible['product']) {
                  is_same_item = orderable_tangible['product']['id'] == selected_orderable_tangible['product']['id'];
                } else if (orderable_tangible['order_package'] && selected_orderable_tangible['order_package']) {
                  is_same_item = orderable_tangible['order_package']['_id'] == selected_orderable_tangible['order_package']['_id'];
                } else if (orderable_tangible['voucher'] && selected_orderable_tangible['voucher']) {
                  is_same_item = orderable_tangible['voucher']['id'] == selected_orderable_tangible['voucher']['id'];
                }

                if (is_same_item) {
                  selected_orderable_tangible['tangible_quantities'].forEach(selected_tangible_quantity => {
                    if (selected_tangible_quantity['id'] == tangible_quantity['id']) {
                      total_selected_consumed_tangible_quantity += selected_tangible_quantity['quantity'];
                    }
                  });
                }
              });
            });

            let combo = _.find(combos, { tangible_id: String(tangible_quantity['id']) });

            if (!_.isNil(combo)) {
              let desired_tangible_quantity = Number(tangible_quantity['quantity']);
              let allowed_tangible_quantity = combo['quantity'];
              let remaining_allowed_tangible_quantity = allowed_tangible_quantity - combo_selected_consumed_tangible_quantity;
              let remaining_desired_tangible_quantity = desired_tangible_quantity - total_selected_consumed_tangible_quantity;
              let required_tangible_quantity = Math.min(allowed_tangible_quantity, remaining_desired_tangible_quantity);

              console.log('tangible', tangible_quantity['name']);
              console.log('desired_tangible_quantity', desired_tangible_quantity);
              console.log('allowed_tangible_quantity', allowed_tangible_quantity);
              console.log('combo_selected_consumed_tangible_quantity', combo_selected_consumed_tangible_quantity);
              console.log('total_selected_consumed_tangible_quantity', total_selected_consumed_tangible_quantity);
              console.log('remaining_allowed_tangible_quantity', remaining_allowed_tangible_quantity);
              console.log('remaining_desired_tangible_quantity', remaining_desired_tangible_quantity);
              console.log('required_tangible_quantity', required_tangible_quantity);
              console.log('same_item_found_in_selected_order', same_item_found_in_selected_order);

              if (orderable_tangible['order_package']) {
                //if (remaining_desired_tangible_quantity < desired_tangible_quantity) {
                if (remaining_desired_tangible_quantity < desired_tangible_quantity || desired_tangible_quantity > allowed_tangible_quantity) {
                  can_order = false; //must have complete quantities for package
                  console.log(
                    'Order Package not achievable',
                    orderable_tangible['order_package'],
                    remaining_desired_tangible_quantity,
                    desired_tangible_quantity
                  );
                }
              } else if (remaining_allowed_tangible_quantity == 0 || required_tangible_quantity == 0) {
                can_order = false; //if nothing available to order
              } else if (required_tangible_quantity < 0 && !same_item_found_in_selected_order) {
                //negative quantity and yet nothing available to remove from selected, cannot
                can_order = false; //if nothing available to order
              } else {
                //make quanitity adjustment, otherwise, no problem ordering

                //IMPORTANT NOTE: the below is the source of reactivity issue
                tangible_quantity['quantity'] = required_tangible_quantity; //adjust quantity to max for product / voucher claims

                //NOTE: the below only works assuming the item is only accociated with 1 tangible
                if (orderable_tangible['product']) {
                  orderable_tangible['product']['include_products']['quantity'] = required_tangible_quantity;
                } else if (orderable_tangible['voucher']) {
                  orderable_tangible['voucher']['claim_vouchers']['claim_quantity'] = required_tangible_quantity;
                }
              }
            } else {
              can_order = false;
              //TODO: should be an error if we couldn't find the combo
            }
          });

          if (can_order) {
            achievable_order['orderable_tangibles'].push(orderable_tangible);
          }
        });

        //Patch orderable_tangibles in case product remove from desired items but was selected. in that case, set that item quantity to zero to allow proper accounting of removed items

        // let selected_order = _.find(this.selected_orders, { id: achievable_order['id'] });

        if (selected_order) {
          selected_order['orderable_tangibles'].forEach(existing_orderable_tangible => {
            let existing_product_id = _.get(existing_orderable_tangible, ['product', 'id']);
            if (existing_product_id) {
              if (!_.find(this.orderable_tangibles, { product: { id: existing_product_id } })) {
                let cloned_orderable_tangible = _.cloneDeep(existing_orderable_tangible);
                //if selected item does not exist current orderable_tangibles
                cloned_orderable_tangible['product']['include_products']['quantity'] = -cloned_orderable_tangible['product']['include_products']['quantity'];
                cloned_orderable_tangible['tangible_quantities'].forEach(tangible_quantity => {
                  tangible_quantity['quantity'] = -tangible_quantity['quantity'];
                });

                achievable_order['orderable_tangibles'].push(cloned_orderable_tangible);
              }
            }

            let existing_order_package_id = _.get(existing_orderable_tangible, ['order_package', '_id']);
            if (existing_order_package_id) {
              if (!_.find(this.orderable_tangibles, { order_package: { _id: existing_order_package_id } })) {
                let cloned_orderable_tangible = _.cloneDeep(existing_orderable_tangible);
                //if selected item does not exist current orderable_tangibles
                cloned_orderable_tangible['order_package']['remove'] = true;
                achievable_order['orderable_tangibles'].push(cloned_orderable_tangible);
              }
            }

            let existing_voucher_id = _.get(existing_orderable_tangible, ['voucher', 'id']);
            if (existing_voucher_id) {
              if (!_.find(this.orderable_tangibles, { voucher: { id: existing_voucher_id } })) {
                let cloned_orderable_tangible = _.cloneDeep(existing_orderable_tangible);
                //if selected item does not exist current orderable_tangibles
                cloned_orderable_tangible['voucher']['claim_vouchers']['claim_quantity'] = -cloned_orderable_tangible['voucher']['claim_vouchers'][
                  'claim_quantity'
                ];
                cloned_orderable_tangible['tangible_quantities'].forEach(tangible_quantity => {
                  tangible_quantity['quantity'] = -tangible_quantity['quantity'];
                });

                achievable_order['orderable_tangibles'].push(cloned_orderable_tangible);
              }
            }
          });
        }

        return achievable_order;
      });

      console.log('achievable_orders', achievable_orders);
      return achievable_orders;
      // return this.order_quota_accepted;
    },
    computed_selected_orders() {
      let computed_selected_orders = _.map(this.selected_orders, ori_selected_order => {
        let selected_order = _.clone(ori_selected_order);
        let combos = _.filter(this.computed_quota_data, { id: selected_order['id'] }); //this ensures the combo is fresh
        selected_order['combos'] = combos;

        let achievable_order = Object.assign({}, combos[0]);

        let consume_addresses_per_day = 1;
        let consume_volume_per_day = 0;
        let consume_weight_per_day = 0;

        selected_order['orderable_tangibles'].forEach(orderable_tangible => {
          orderable_tangible['tangible_quantities'].forEach(tangible_quantity => {
            consume_volume_per_day += tangible_quantity['quantity'] * tangible_quantity['volume_per_unit'];
            consume_weight_per_day += tangible_quantity['quantity'] * tangible_quantity['weight_per_unit'];
          });
        });

        selected_order['consumed_volume_per_day'] = achievable_order['consumed_volume_per_day'] + consume_volume_per_day;
        selected_order['consumed_weight_per_day'] = achievable_order['consumed_weight_per_day'] + consume_weight_per_day;
        selected_order['consumed_addresses_per_day'] = achievable_order['consumed_addresses_per_day'] + consume_addresses_per_day;

        selected_order['limited_volume_per_day'] = achievable_order['limited_volume_per_day'];
        selected_order['limited_weight_per_day'] = achievable_order['limited_weight_per_day'];
        selected_order['limited_addresses_per_day'] = achievable_order['limited_addresses_per_day'];

        this.calcRemaining(selected_order);

        selected_order['has_issue'] = this.hasIssue(selected_order);

        return selected_order;
      });
      return computed_selected_orders;
    },
  },
  watch: {
    involved_tangibles: {
      deep: true,
      handler() {
        this.generateQuotaTableDebounced();
      },
    },
    quota_data: {
      deep: true,
      handler() {
        this.generateQuotaTableDebounced();
      },
    },
  },
  created() {
    this._ = _;
    this.init();
    this.getAreasByPointsDebounced = _.debounce(this.getAreasByPoints, 500);
    this.generateQuotaTableDebounced = _.debounce(this.generateQuotaTable, 500);
  },
  methods: {
    getAreasByPoints(points) {
      console.log('getAreasByPoints!', points);

      points.forEach(point => {
        if (point['center_point']) {
          let sa_index_params = {
            filters: [
              {
                filter_type: 'property',
                existence: 'present',
                property_key: 'polygon',
                max_distance: {
                  distance: 0.02,
                  /* center_point: {
                type: 'Point',
                coordinates: [101.54751590709043, 3.042366502337232],
              }, */
                  center_point: point['center_point'],
                },
              },
            ],
          };

          this.loading_areas = true;
          this.$d.request(
            'index',
            'Area',
            sa_index_params,
            sa_class_data => {
              console.log('Area class_data', sa_class_data);
              // point['areas'] = sa_class_data;
              let sa_names = _.map(sa_class_data, sa => {
                return sa.name;
              });

              point['area_names'] = sa_names.join('\n');
              this.loading_areas = false;
            },
            error_message => {
              this.loading_areas = false;
              console.log(error_message);
            }
          );
        }
      });
    },

    init() {
      this.order['order_bill_to'] = [{ id: this.billing_account.id }];
      this.order['order_under_account'] = [{ id: this.account.id }];
      this.order['order_deliver_to'] = _.get(this.account, [this.$d.getRelationAliasByNames('Account', 'HasAddresses')], []);

      // let init_date = '2019-06-01';
      let init_date = this.$d.momentFormatDate(moment().add(1, 'day'));

      // this.ideal_params.from_date = this.$d.momentFormatDate(moment());
      // this.ideal_params.from_date = init_date;
      // this.ideal_params.to_date = this.$d.momentFormatDate(moment(this.ideal_params.from_date).add(4, 'weeks'));

      let disabled_weekdays = [0];
      this.ideal_params['dates'] = [];
      for (let i = 0; i < 14; i++) {
        let moment_date = moment(init_date).add(i, 'day');

        if (!disabled_weekdays.includes(moment_date.day())) {
          //if not sunday
          this.ideal_params['dates'].push(this.$d.momentFormatDate(moment_date));
        }
      }
    },

    quotaCheck() {
      let request_datetime = moment();
      this.loading_quota_check = true;
      this.quota_check_error_message = null;
      this.ideal_params['tangibles'] = this.involved_tangibles;
      this.ideal_params['addresses'] = this.order['order_deliver_to'];
      if (this.order['order_deliver_to'].length > 0){
        this.got_address = true;
      } else {
        this.got_address = false;
      }
      this.$d.request(
        'quota_check',
        'Order',
        this.ideal_params,
        class_data => {
          console.log('class_data', class_data);
          this.quota_data = class_data.output.data;
          this.generateQuotaTable();
          this.loading_quota_check = false;
          
          let response_datetime = moment();
          this.timetaken = response_datetime.diff(request_datetime, 'ms');
        },
        error_message => {
          this.loading_quota_check = false;
          console.log(error_message);
          this.quota_check_error_message = error_message;
        }
      );
    },

    generateQuotaTable() {
      console.log('generateQuotaTable');
      let achievable_combos = this.computed_quota_data;
      let order_quota_invalid = [];
      let order_quota_negative = [];
      let order_quota_positive = [];
      achievable_combos.forEach(ori_achievable_combo => {
        let achievable_combo = _.cloneDeep(ori_achievable_combo);

        achievable_combo.reason = '';

        achievable_combo.day_of_week_name = moment(achievable_combo.from_date).format('dddd');

        //check combo validity

        if (!achievable_combo.service_route_availability_id) {
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          // achievable_combo.reason += 'ServiceRouteAvailability not set OR no ServiceRoute';
          achievable_combo.reason += 'ServiceRoute or ServiceRouteAvailability not set';
        }
        if (achievable_combo.service_route_availability_id && !achievable_combo.warehouse_quota_id) {
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'WarehouseQuota not set';
        }
        if (achievable_combo.service_route_availability_id && !achievable_combo.lorry_capacity_id) {
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'LorryCapacity not set';
        }
        if (achievable_combo.service_route_availability_id && !achievable_combo.driver_capability_id) {
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'DriverCapability not set';
        }

        if (achievable_combo.reason) {
          order_quota_invalid.push(achievable_combo);
          return;
        }

        //check combo not achievable

        if (achievable_combo.route_date_is_blocked == '1') {
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'Route Date is blocked';
        }

        if (
          achievable_combo.service_route_availability_id &&
          (!achievable_combo.service_route_is_available || achievable_combo.service_route_is_available == '0')
        ) {
          console.log('SR not avial', achievable_combo);
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'Service Route For the Area is not Available';
        } else {
          console.log('SR IS avial', achievable_combo);
        }

        //find ideal tangible

        let ideal_tangible = _.cloneDeep(_.find(this.involved_tangibles, { id: Number(achievable_combo.tangible_id) }));

        let ideal_quantity = 0;
        let ideal_volume_per_unit = 0;
        let ideal_weight_per_unit = 0;
        achievable_combo.limiting_reason = '';
        if (ideal_tangible) {
          // console.log('ideal_tangible', achievable_combo.tangible_id, this.ideal_params.tangibles, ideal_tangible);

          ideal_quantity = ideal_tangible.quantity;
          ideal_volume_per_unit = ideal_tangible.volume_per_unit || 0;
          ideal_weight_per_unit = ideal_tangible.weight_per_unit || 0;
          // let ideal_total_volume = ideal_volume_per_unit * ideal_quantity;
          // let ideal_total_weight = ideal_weight_per_unit * ideal_quantity;
        }

        //check limited_quantity_per_day

        if (achievable_combo.limited_quantity_per_day && achievable_combo.remaining_quantity_per_day < 1) {
          //if can't even add a single item
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'Warehouse Quantity Maxed';
        }

        //check limited_volume_per_day

        if (achievable_combo.limited_volume_per_day && achievable_combo.remaining_volume_per_day < ideal_volume_per_unit) {
          //if negative
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'Lorry Volume Maxed';
        }

        //check limited_weight_per_day

        if (achievable_combo.limited_weight_per_day && achievable_combo.remaining_weight_per_day < ideal_weight_per_unit) {
          //if negative
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'Lorry Weight Maxed';
        }

        //check limited_addresses_per_day

        if (achievable_combo.limited_addresses_per_day && achievable_combo.remaining_addresses_per_day < 1) {
          //if if can't even add a single item (TODO: might actually be same address and should be able to add)
          if (achievable_combo.reason) {
            achievable_combo.reason += '\n';
          }
          achievable_combo.reason += 'Driver Addresses Maxed';
        }

        if (ideal_tangible) {
          //make adjustments to combo quantity if needed
          //NOTE: this block of code should not be for each combo, it only works because each product & voucher is only associated to 1 tangible

          let possible_quantities = [];
          possible_quantities.push(ideal_quantity);

          //max quantity based on warehouse quantity
          let max_quantity_by_warehouse = achievable_combo['remaining_quantity_per_day'];
          if (ideal_quantity > max_quantity_by_warehouse) {
            possible_quantities.push(max_quantity_by_warehouse);

            if (achievable_combo.limiting_reason) {
              achievable_combo.limiting_reason += '\n';
            }
            achievable_combo.limiting_reason += 'Lacking ' + (ideal_quantity - max_quantity_by_warehouse) + 'units';
          }

          //max quantity based on lorry weight
          let max_quantity_by_volume = Math.floor(achievable_combo['remaining_volume_per_day'] / ideal_volume_per_unit);
          if (ideal_quantity > max_quantity_by_volume) {
            possible_quantities.push(max_quantity_by_volume);

            if (achievable_combo.limiting_reason) {
              achievable_combo.limiting_reason += '\n';
            }
            achievable_combo.limiting_reason += 'Over volume by ' + (ideal_quantity - max_quantity_by_volume) + 'units';
          }

          //max quantity based on lorry weight
          let max_quantity_by_weight = Math.floor(achievable_combo['remaining_weight_per_day'] / ideal_weight_per_unit);
          if (ideal_quantity > max_quantity_by_weight) {
            possible_quantities.push(max_quantity_by_weight);

            if (achievable_combo.limiting_reason) {
              achievable_combo.limiting_reason += '\n';
            }
            achievable_combo.limiting_reason += 'Over weight by ' + (ideal_quantity - max_quantity_by_weight) + 'units';
          }

          achievable_combo['quantity'] = _.min(possible_quantities);
        } else {
          achievable_combo['quantity'] = achievable_combo['remaining_quantity_per_day'];
        }

        if (!achievable_combo.reason) {
          order_quota_positive.push(achievable_combo);
        } else {
          order_quota_negative.push(achievable_combo);
        }
      });

      this.order_quota_invalid = order_quota_invalid;
      this.order_quota_negative = order_quota_negative;
      this.order_quota_positive = order_quota_positive;

      console.log('order_quota_invalid', this.order_quota_invalid);
      console.log('order_quota_negative', this.order_quota_negative);
      console.log('order_quota_positive', this.order_quota_positive);
    },

    dayOfWeekClass(value, key, item) {
      if (value == 'Monday') {
        return 'bg-primary';
      } else if (value == 'Tuesday') {
        return 'bg-info';
      } else if (value == 'Wednesday') {
        return 'bg-success';
      } else if (value == 'Thursday') {
        return 'bg-warning';
      } else if (value == 'Friday') {
        return 'bg-danger';
      } else if (value == 'Saturday') {
        return 'bg-dark';
      }
      return null;
    },

    calcRemaining(info) {
      if (!_.isNil(info['limited_quantity_per_day'])) {
        info['remaining_quantity_per_day'] = info['limited_quantity_per_day'] - info['consumed_quantity_per_day'];
      }
      if (!_.isNil(info['limited_volume_per_day'])) {
        info['remaining_volume_per_day'] = info['limited_volume_per_day'] - info['consumed_volume_per_day'];
      }
      if (!_.isNil(info['limited_weight_per_day'])) {
        info['remaining_weight_per_day'] = info['limited_weight_per_day'] - info['consumed_weight_per_day'];
      }
      if (!_.isNil(info['limited_addresses_per_day'])) {
        info['remaining_addresses_per_day'] = info['limited_addresses_per_day'] - info['consumed_addresses_per_day'];
      }
    },

    hasIssue(info) {
      let has_issue = false;

      if (!_.isNil(info['remaining_quantity_per_day']) && info['remaining_quantity_per_day'] <= 0) {
        has_issue = true;
      }
      if (!_.isNil(info['remaining_volume_per_day']) && info['remaining_volume_per_day'] < 0) {
        has_issue = true;
      }
      if (!_.isNil(info['remaining_weight_per_day']) && info['remaining_weight_per_day'] < 0) {
        has_issue = true;
      }
      if (!_.isNil(info['remaining_addresses_per_day']) && info['remaining_addresses_per_day'] < 0) {
        has_issue = true;
      }

      return has_issue;
    },

    createOrder(achievable_order) {
      this.create_loading = true;

      console.log('achievable_order', achievable_order);
      let address = _.find(this.order['order_deliver_to'], { id: Number(achievable_order['address_id']) });

      // console.log(address,this.order['order_deliver_to'], achievable_order['address_id'])

      let getData = (class_name, id, extra_params) => {
        return new Promise((resolve, reject) => {
          let temp_params = {};
          if (id) {
            temp_params['id'] = id;
          }

          temp_params = Object.assign(temp_params, extra_params);
          this.$d.request(
            id ? 'read' : 'index',
            class_name,
            temp_params,
            class_data => {
              console.log(class_name, class_data);
              resolve(class_data);
            },
            error_message => {
              console.error(class_name, error_message);
              reject(error_message);
            }
          );
        });
      };

      let product_index_params = {};

      product_index_params['with_virtual_properties'] = this.$d.withVirtualPropertiesByRelationship(
        this.order,
        this.$d.getRelationship('Order', 'IncludeProducts')
      );

      let expression_filters = [];

      this.order[this.$d.getRelationAliasByNames('Order', 'IncludeProducts')].forEach(relation => {
        expression_filters.push({ filter_type: 'property', existence: 'present', property_key: 'id', equal: relation['id'] });
      });

      product_index_params['filters'] = [
        {
          filter_type: 'expression',
          operator: 'or',
          filters: expression_filters,
        },
      ];

      Promise.all([
        // getData('Address', achievable_order['address_id']),
        address,
        getData('Warehouse', achievable_order['warehouse_id']),
        getData('ServiceRoute', achievable_order['service_route_id'], {
          with_relationships: [
            {
              from_class_name: 'ServiceRoute',
              relationship_name: 'ForFleet',
            },
          ],
        }),
        getData('Area', achievable_order['area_id']),
        getData('User', this.$auth['user']['id'], {
          with_relationships: [
            {
              from_class_name: 'Salesperson',
              relationship_name: 'WithUser',
            },
          ],
        }),
        getData('Product', null, product_index_params),
      ]).then(values => {
        this.create_loading = false;

        let address = values[0];
        let warehouse = values[1];
        let service_route = values[2];
        let area = values[3];
        let user = values[4];
        let indexed_products = values[5];

        console.log('indexed_products', indexed_products);

        let salesperson = _.get(user, [this.$d.getRelationAliasByNames('Salesperson', 'WithUser'), 0]);

        let products = [];
        let order_packages = [];
        let vouchers = [];
        achievable_order['orderable_tangibles'].forEach(orderable_tangible => {
          if (orderable_tangible['product']) {
            let product = orderable_tangible['product'];

            let indexed_product = _.find(indexed_products, { id: product['id'] });

            console.log('indexed_product', indexed_product);
            product['final_address_pricing'] = indexed_product['final_address_pricing'];
            product['include_products']['price'] = product['final_address_pricing']; //set default price
            console.log('product', product);
            products.push(product);
          } else if (orderable_tangible['order_package']) {
            order_packages.push(orderable_tangible['order_package']);
          } else if (orderable_tangible['voucher']) {
            vouchers.push(orderable_tangible['voucher']);
          }
        });

        console.log('CREATE ORDER', address, warehouse, service_route, area, user, salesperson, products, order_packages, vouchers);

        //////

        let address_contact_persons = _.get(address, this.$d.getRelationAliasByNames('Address', 'HasContactPersons'));
        let account_delivery_contact_persons = _.get(this.account, this.$d.getRelationAliasByNames('Account', 'HasMainDeliveryContactPersons'));
        let order_contact_persons = (address_contact_persons && address_contact_persons.length) ? address_contact_persons : account_delivery_contact_persons // defaults to use contact from address, otherwise use the one from account setting
        let fleet = service_route['service_route_for_fleet'];

        this.order_modal_params = _.merge(
          this.$d.relationFixedParams('Order', 'DeliverTo', [address]),
          this.$d.relationFixedParams('Order', 'FromWarehouse', [warehouse]),
          this.$d.relationFixedParams('Order', 'ForFleet', fleet),
          this.$d.relationFixedParams('Order', 'UnderServiceRoute', [service_route]),
          this.$d.relationFixedParams('Order', 'UnderAreas', [area]),
          // this.$d.relationFixedParams('Order', 'BySalesperson', salesperson ? [salesperson] : []), //comment to disable auto set
          this.$d.relationFixedParams('Order', 'IncludeProducts', products),
          this.$d.relationFixedParams('Order', 'IncludeOrderPackages', order_packages),
          this.$d.relationFixedParams('Order', 'ClaimVouchers', vouchers),
          this.$d.relationFixedParams('Order', 'WithDeliveryContacts', order_contact_persons),
          this.$d.relationFixedParams(
            'Order',
            'InvoiceTo',
            _.get(this.billing_account, this.$d.getRelationAliasByNames('BillingAccount', 'HasMainInvoiceContactPersons'))
          )
        );
        this.order_modal_params['desired_delivery_date'] = achievable_order['from_date'];

        //////

        this.order_modal_fixed_params = _.merge(
          this.$d.relationFixedParams('Order', 'BillTo', [this.billing_account]),
          this.$d.relationFixedParams('Order', 'UnderAccount', [this.account])
        );

        console.log(this.order_modal_fixed_params);
        console.log(this.order_modal_params);

        setTimeout(() => {
          this.$refs['orderModalRef'].open();
        }, 0);
      });
    },

    addOrderableTangible(achievable_order, orderable_tangible) {
      console.log('addOrderableTangible', achievable_order, orderable_tangible);
      let temp_achievable_order = _.clone(achievable_order);

      //remove potentially outdated data, need to get it fresh from order_quota_positive
      delete temp_achievable_order['consumed_volume_per_day'];
      delete temp_achievable_order['consumed_weight_per_day'];
      delete temp_achievable_order['consumed_addresses_per_day'];

      delete temp_achievable_order['limited_volume_per_day'];
      delete temp_achievable_order['limited_weight_per_day'];
      delete temp_achievable_order['limited_addresses_per_day'];

      delete temp_achievable_order['remaining_volume_per_day'];
      delete temp_achievable_order['remaining_weight_per_day'];
      delete temp_achievable_order['remaining_addresses_per_day'];

      delete temp_achievable_order['has_issue'];

      delete temp_achievable_order['combos'];

      temp_achievable_order['orderable_tangibles'] = [];

      let selected_order = _.find(this.selected_orders, { id: achievable_order['id'] });
      if (!selected_order) {
        this.selected_orders.push(temp_achievable_order);
      }

      selected_order = _.find(this.selected_orders, { id: achievable_order['id'] });

      let existing_orderable_tangible_index = -1; //invalid index
      if (existing_orderable_tangible_index < 0 && orderable_tangible['product']) {
        existing_orderable_tangible_index = _.findIndex(selected_order['orderable_tangibles'], { product: { id: orderable_tangible['product']['id'] } });
      }

      if (existing_orderable_tangible_index < 0 && orderable_tangible['order_package']) {
        existing_orderable_tangible_index = _.findIndex(selected_order['orderable_tangibles'], {
          order_package: { _id: orderable_tangible['order_package']['_id'] },
        });
      }

      if (existing_orderable_tangible_index < 0 && orderable_tangible['voucher']) {
        existing_orderable_tangible_index = _.findIndex(selected_order['orderable_tangibles'], { voucher: { id: orderable_tangible['voucher']['id'] } });
      }

      if (existing_orderable_tangible_index >= 0) {
        let existing_orderable_tangible = selected_order['orderable_tangibles'][existing_orderable_tangible_index];
        console.log('existing_orderable_tangible FOUND', existing_orderable_tangible);

        orderable_tangible['tangible_quantities'].forEach(tangible_quantity => {
          let existing_tangible_quantity = _.find(existing_orderable_tangible['tangible_quantities'], { id: tangible_quantity['id'] });
          existing_tangible_quantity['quantity'] = Number(existing_tangible_quantity['quantity']) + Number(tangible_quantity['quantity']);
        });

        if (existing_orderable_tangible['product']) {
          existing_orderable_tangible['product']['include_products']['quantity'] =
            Number(existing_orderable_tangible['product']['include_products']['quantity']) +
            Number(orderable_tangible['product']['include_products']['quantity']);

          if (existing_orderable_tangible['product']['include_products']['quantity'] == 0) {
            this.removeOrderableTangible(selected_order, existing_orderable_tangible, existing_orderable_tangible_index);
          }
        } else if (existing_orderable_tangible['order_package']) {
          if (orderable_tangible['order_package']['remove']) {
            this.removeOrderableTangible(selected_order, existing_orderable_tangible, existing_orderable_tangible_index);
          }
        } else if (existing_orderable_tangible['voucher']) {
          existing_orderable_tangible['voucher']['claim_vouchers']['claim_quantity'] =
            Number(existing_orderable_tangible['voucher']['claim_vouchers']['claim_quantity']) +
            Number(orderable_tangible['voucher']['claim_vouchers']['claim_quantity']);

          if (existing_orderable_tangible['voucher']['claim_vouchers']['claim_quantity'] == 0) {
            this.removeOrderableTangible(selected_order, existing_orderable_tangible, existing_orderable_tangible_index);
          }
        }

        console.log('existing_orderable_tangible UPDATED', existing_orderable_tangible);
      } else {
        console.log('existing_orderable_tangible NOT found');
        selected_order['orderable_tangibles'].push(orderable_tangible);
      }

      console.log(selected_order);
    },
    removeOrderableTangible(selected_order, orderable_tangible, selected_orderable_tangible_index) {
      let selected_order_index = _.findIndex(this.selected_orders, { id: selected_order['id'] });

      /* console.log('remove selected_order_index', selected_order_index, selected_order['id'], this.selected_orders);
      let selected_orderable_tangible_index = _.findIndex(this.selected_orders[selected_order_index]['orderable_tangibles'], { id: orderable_tangible['id'] });

      console.log('remove selected_orderable_tangible_index', selected_orderable_tangible_index, orderable_tangible['id'], this.selected_orders[selected_order_index]['orderable_tangibles']);
      if(selected_orderable_tangible_index != -1){
        this.selected_orders[selected_order_index]['orderable_tangibles'].splice(selected_orderable_tangible_index, 1);
      } */

      this.selected_orders[selected_order_index]['orderable_tangibles'].splice(selected_orderable_tangible_index, 1);

      if (this.selected_orders[selected_order_index]['orderable_tangibles'].length == 0) {
        //if the order is empty, delete that order too
        this.selected_orders.splice(selected_order_index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* .card-body  thead > tr > th,
.card-body  tbody > tr > td {
  white-space: nowrap !important;
} */
.quota_table  {
  tbody > tr > td {
    white-space: pre-wrap;
    @media (min-width: 576px) {
      white-space: pre;
    }

    &.no_break {
      white-space: nowrap !important;
      vertical-align: middle;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .table-sm .btn-sm {
    padding: 0.1rem 0.5rem;
  }
}

.order_generator  .card-header {
  font-weight: bold;
}
</style>
